*{
  margin:0;
  padding:0;
  box-sizing: border-box;
  font-size: 24px;
  font-family: 'Dancing Script', cursive;
}

body{
  height: auto;
  width: 100vw;
  background-color: rgba(254, 184, 9, 1);
}

h1{
  font-size: 3rem;
}
h2{
  margin-bottom: 10px;
}

button{
  outline: none;

}
ul{
  list-style: none;
  text-decoration: none;
  text-transform: none;
}
p{
  font-size: 18px;
  margin-bottom: 10px;
}

cite{
  display: block;
  text-align: right;
  /* color:white; */
}
.half{
  width: 50%;
}
/* PASSWORD PAGE */

#passPage-section{
  height: 100vh;
  width: 100vw;
  background-color: black;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#passPage-section h1{
  color: white;
  text-align: center;
}
#passPage-section form{
  width: 100%;
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#passPage-section form input{
  width: 20%;
  outline: none;
  margin-bottom: 10px;
  padding: 10px;

  background-color: black;
  color: white;
  border: solid 3px white;
}
#passPage-section form button{
  width:20%;
  background-color: white;
  border: 0;
  color: black;
  padding: 10px;
}
#passPage-section form button:hover{
  cursor: pointer;
  background-color: rgba(254, 184, 9, 1);
  color: white;
}

/* MAKBOARD/SAMBOARD */

#board{
  min-height:100vh;
  height: auto;
  width: 100vw;

  background-image: url("https://img.freepik.com/free-vector/stylish-hexagonal-line-pattern-background_1017-19742.jpg?size=626&ext=jpg");
  background-image: url("https://www.xmple.com/wallpaper/stripes-streaks-black-white-lines-2732x2048-c2-000000-f8f8ff-l2-126-126-a-0-f-1.svg");
  background-image: url("https://i.pinimg.com/originals/7b/5a/f1/7b5af14c30856265c22f22b45d62c68d.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;

  display: flex;
  /* display: none; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#board > h1{
  position: absolute;
  top: calc(50% - 3rem - 40px);
  z-index: 1;
  background-color: rgba(254, 184, 9, 1);
  padding: 40px;
  text-align: center;
}

#board #scrollingBoard{
  /* display: none; */
  border: rgba(254, 184, 9, 1) solid 5px;
  border-bottom: 0;
  width: 90%;
  min-height: 90vh;
  margin-top: 100%;
  background-color: #f1f1f1;
  background-color: black;
  border-radius: 10px 10px 0 0;
  /* position: absolute; */
  /* bottom: 0; */
  z-index: 2;

  animation: slide-up 3s forwards;
  animation-delay: 1s;
}
@keyframes slide-up{
  from {
    margin-top: 100%;
  }
  to {
    margin-top: 50px;
  }
}

#scrollingBoard > *{
  padding: 20px;
}

#scrollingBoard > #header-nav h1 {
  padding: 40px;
  color: white;
  border-radius: 5px 5px 0 0;
  /* background-color: rgba(254, 184, 9, 1); */
  text-align: center;
}
#header-nav  h1 span{
  height: 1.5rem;
  width: 1,5rem;
  font-size: 1.5rem;
  border-radius: calc(1.5rem / 2);
  background-color: white;
  padding: 10px;
  color:rgba(254, 184, 9, 1)
}
#scrollingBoard > div#board-main{
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
#scrollingBoard > div#board-main div.half {
  height: 100%;
  /* background-color: lawngreen; */
  padding: 0 10px;
}
#scrollingBoard .half > div {
  padding: 20px;
  margin-bottom: 10px;
  background-color: #c1c1c1;
  border-radius: 10px;
}
#scrollingBoard .half img{
  border-radius: 10px;
  border: 5px solid rgba(254, 184, 9);
  filter: grayscale(100%);
}
#scrollingBoard > div#board-main div.half #event-details{
  background-color: black;
}
#scrollingBoard > div#board-main div.half #event-details > *{
  color:white;
}
#event-details h2{
  font-family: 'Playfair Display', serif;
}
#event-details p
{
  /* text-align: center; */
  /* display: inline-block; */
  margin-bottom: 20px;
}
#event-details p span {
  font-family: 'Playfair Display', serif;
  font-weight: 700;
}
/* #expect h2{
  font-family: 'Playfair Display', serif;
} */

/* COMMENTED OUT FOR STYLE CONVENIENCE ORGANIZE LATER!!!
/* #rsvp{
  font-family: 'Playfair Display', serif;
  width: 100%;
  padding: 10px;
  background-color: white;
  color: black;
  font-size: 3rem;
  border: none;
  outline: none;
  border-radius: 10px;
}
#rsvp:hover{
  cursor: pointer;
  background-color: rgba(254, 184, 9, 1);
  color: white;
}
button#rsvp.checkedIN{
  cursor: not-allowed;
  background-color: black;
  color: white;
  border: 5px solid rgba(254, 184, 9, 1);
} */

#scrollingBoard > div#board-main div.half #checklist{
  background-color: white;
}

#checklist h2{
  text-align: center;
  font-family: 'Playfair Display', serif;
}

#exit{
  text-align: center;
  display: none;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  background-color: rgba(254, 184, 9, 1);
  color: white;
}
#exit2{
  position: absolute;
  /* top: 0; */
  margin-left: 20px;
  text-align: center;
  /* display: block; */
  /* width: 100%; */
  border-radius: 10px;
  padding: 10px;
  background-color: rgba(254, 184, 9, 1);
  color: white;

  opacity:0;
  animation: fade-in 2s forwards;
  animation-delay: 8s;
}
@keyframes fade-in{
  from{
    opacity: 0
  }
  to{
    opacity: 1
  }
}
#exit:hover, #exit2:hover{
  cursor: pointer;
  color: black;
}

/* COMMENTED OUT FOR STYLISTIC CONVENIENCE!!! ORGANIZE LATER!

USER CHECKLIST STYLING
Style the list items
ul li {
  cursor: pointer;
  position: relative;
  padding: 12px 8px 12px 40px;
  background: #eee;
  font-size: 18px;
  transition: 0.2s;

  make the list items unselectable
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

Set all odd list items to a different color (zebra-stripes)
ul li:nth-child(odd) {
  background: #f9f9f9;
}

Darker background-color on hover
ul li:hover {
  background: #ddd;
}

When clicked on, add a background color and strike out text
ul li.checked {
  background: #888;
  color: #fff;
  text-decoration: line-through;
}

Add a "checked" mark when clicked on
ul li.checked::before {
  content: '';
  position: absolute;
  border-color: #fff;
  border-style: solid;
  border-width: 0 2px 2px 0;
  top: 10px;
  left: 16px;
  transform: rotate(45deg);
  height: 15px;
  width: 7px;
} */

/* Style the close button */
.close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 12px 16px 12px 16px;
}

.close:hover {
  background-color: #f44336;
  color: white;
}

/* Style the input */
#checklist input {
  /* margin: 0; */
  display: inline;
  border: none;
  border-radius: 0;
  width: 75%;
  padding: 10px;
  float: left;
  font-size: 16px;
  outline: none;
}

/* Style the "Add" button */
.addBtn {
  display: inline;
  padding: 10px;
  width: 25%;
  background: #d9d9d9;
  color: #555;
  /* float: left; */
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  transition: 0.3s;
  border: none;
  border-radius: 0;
}

.addBtn:hover {
  background-color: #bbb;
}




/* COME BACK SOON */
#comeback-section{
  height: 100vh;
  width: 100vw;
  background-color: black;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#comeback-section h1{
  color: white;
}
#comeback-section #enter{
  position: absolute;
  bottom: 50px;
  right: 50px;
  background-color: rgba(254, 184, 9, 1);
  padding: 10px;
  color:white;
  border-radius: 10px;

  opacity: 0;
  animation: fade-in 3s forwards;
  animation-delay: 2s;
}
#comeback-section #enter:hover{
  color:black;
  cursor: pointer;
}

#nah{
  height:100vh;
  width: 100vw;
  background-color: black;
  display: flex;
  flex-direction: column;

  justify-content: center;
}

#nah h1, #nah h3{
  color: white;
  text-align: center;
}
#nah #enter{
  position: absolute;
  bottom: 50px;
  right: 50px;
  background-color: rgba(254, 184, 9, 1);
  padding: 10px;
  color:white;
  border-radius: 10px;

  /* opacity: 0; */
  /* animation: fade-in 5s forwards; */
  /* animation-delay: 5s; */
}
#nah #enter:hover{
  color:black;
  cursor: pointer;
}

#loading{
  height:100vh;
  /* width: 100vw; */
  background-color: black;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#loading h1{
  color: white;
  text-align: center;
}
#loading #myLoad{
  /* background-color: #555; */
  /* width: 100%; */
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1000px) and (min-width: 701px){
  #passPage-section form input,
  #passPage-section form button{
    width:40%
  }
}
@media screen  and (max-width: 701px) and (min-width: 0px) {
  #passPage-section form input,
  #passPage-section form button{
    width:70%
  }
  #exit2{
    display: none;
  }
  #exit{
    display: block;
  }
  #board #scrollingBoard{
    border-color: black;
  }
  #scrollingBoard > div#board-main{
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  #scrollingBoard > #header-nav h1 {
    padding: 20px;
  }

  .half{
    width: 100%;
    margin-bottom: 10px;
  }

  #scrollingBoard .half img {
    order: 2
  }

  #scrollingBoard > div#board-main div.half #event-details{
    /* background-color: rgba(254, 184, 9, 1); */
    border: solid 3px rgba(254, 184, 9, 1);
  }
  #scrollingBoard .half img{
    border: none;
  }
}

@media screen and (max-width: 376px) and (min-width:0px){
  h1{font-size: 2.5rem}
}
